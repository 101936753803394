import { Route, Routes } from "react-router-dom"
import { Home } from "../../pages/Home/Home"
import { Contacts } from "../../pages/Contacts/Contacts"

export const Router  = ()=>{
    
    return <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/contacts" element={<Contacts/>}/>
    </Routes>
}