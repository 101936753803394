import styles from "./contacts.module.scss"

export const Contacts = ()=>{
    return <div className={styles.container}>
        <table>
            <tbody>
                <tr>
                    <td className={styles.columnTitle}>Company Registration Number</td>
                    <td>C 107018</td>
                </tr>
                <tr>
                    <td className={styles.columnTitle}>Incorporation Date</td>
                    <td>22-11-2023</td>
                </tr>
                <tr>
                    <td className={styles.columnTitle}>Address</td>
                    <td>
                        <p>47, OYIA Building Level 0</p>
                        <p>Triq Is-Salib Tal-Marsa</p>
                        <p>Marsa, MRS1574</p>
                        <p>Malta</p>
                    </td>
                </tr>
                <tr>
                    <td className={styles.columnTitle}>VAT Number</td>
                    <td>MT 3064-3233</td>
                </tr>
                <tr>
                    <td className={styles.columnTitle}>Tax Number</td>
                    <td>971 841 621</td>
                </tr>
            </tbody>
        </table>
    </div>
}