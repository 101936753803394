import { Menu } from "./components/Menu/Menu";
import { Router } from "./components/Router/Router";
function App() {
  return (
    <div className="container">
      <Menu/>
      <Router/>
    </div>
  );
}

export default App;
